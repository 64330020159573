import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import md5 from "js-md5";
import Vant from "vant";
import axios from "axios";
import "vant/lib/index.css";
import "./components";

Vue.use(Vant);
Vue.prototype.$md5 = md5;
Vue.prototype.$axios = axios;

Vue.config.productionTip = false;
// 获取package.json中version，检测到版本变动，清除浏览器缓存
const VUE_APP_VERSION = require("../package.json").version;
const vers = window.localStorage.getItem("appVersion") || "";
if (VUE_APP_VERSION != vers) {
  localStorage.clear();
  window.localStorage.setItem("appVersion", VUE_APP_VERSION);
  location.reload();
}

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
