<template>
  <div id="app">
    <!-- <keep-alive>
      <router-view
        ref="currentPage"
        @connectSocket="connectSocket"
        @closeSocket="closeSocket"
        @sendSocket="sendSocket"
        @checkList="checkList"
        v-if="$route.meta.keepAlive"
      />
    </keep-alive> -->
    <router-view ref="currentPage" @connectSocket="connectSocket" @closeSocket="closeSocket" @sendSocket="sendSocket"
      @checkConnect="checkConnect" />

    <van-tabbar v-model="active" route fixed v-if="tabbar" active-color="#4CBBF9">
      <van-tabbar-item replace to="/">
        <span>遥控器</span>
        <template #icon="props">
          <img v-if="props.active" src="./assets/tableftone.png" />
          <img v-else src="./assets/tablefttwo.png" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item replace to="/apply">
        <span>应用推送</span>
        <template #icon="props">
          <img v-if="props.active" src="./assets/tabrightone.png" />
          <img v-else src="./assets/tabrighttwo.png" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { cryptoPass, decryptPass } from "@/utils/crypto";
import { Toast } from "vant";

export default {
  data() {
    let active = ["/", "/apply"].indexOf(this.$route.path);
    return {
      active: active,

      socket: null,
      wsUrls: [],
      param: {},
      timeOut: null,

      heartBeat: null,
      isConnect: false,
      isReconnect: false
    };
  },
  computed: {
    tabbar() {
      return ~["/", "/apply"].indexOf(this.$route.path); // 需要显示tabbar的页面
    },
  },
  // created() {
  //   if(!this.socket) {
  //     this.removeStorage()
  //     this.$store.commit('removeList', '')
  //   }
  // },
  watch: {
    active(newVal, oldVal) {
      if (newVal == 1) {
        this.active = oldVal;
      }
    },
    $route(newVal) {
      let active = ["/", "/apply"].indexOf(newVal.path);
      this.active = active;
    },
  },
  created() {
    console.log('版本号1.5')
  },
  methods: {
    //socket连接
    connectSocket(index, param) {
      // flag  从桌面扫码进远程推送进行连socket  
      // flag true 区分是从桌面进入远程推送后进行的连接websocket
      // debugger
      //清空params内参数
      this.param = {}
      if (!this.socket) {
        console.log('准备开始连接socket')
        //获取设备ws地址
        this.wsUrls = JSON.parse(sessionStorage.getItem("apiUrls")) || [];
        console.log(this.wsUrls, index, 'wsUrlsssssssssssssss', this.wsUrls[index])
        if (this.wsUrls.length) {
          this.socket = new WebSocket(this.wsUrls[index]);
        } else {
          let path = ['/upLoad', '/upload', '/recommend']
          console.log(this.$route.path)
          if (path.includes(this.$route.path)) {
            this.$refs.currentPage.close()
          } else {
            Toast.fail('设备连接超时')
          }
          return false
        }

        this.param = param || {}
        //websocket超时处理
        this.timeOut = setTimeout(() => {
          console.log(this.socket, this.socket.url, `socket状态码${this.socket.readyState}`);
          if (this.socket && this.socket.readyState === 0) {
            //readyState 0 表示正在建立连接
            this.socket?.close(3000, "webSocket超时！");
            this.socket = null;
            Toast.clear()
            this.removeStorage()
            let path = ['/upload', '/upload', '/recommend']
            if (path.includes(this.$route.path)) {
              this.$refs.currentPage.close()
            } else {
              Toast.fail('设备连接超时')
            }

          }
        }, 4998);
      } else {
        //如果socket是连接的状态下，param有值则为应用列表页状态刷新
        if (param) {
          this.sendSocket(param);
        }
        this.$refs.currentPage.connected()
      }

      //连接成功
      this.socket.onopen = (res) => {
        console.log("socket连接成功", res);
        Toast.clear()
        if (this.param?.cmd) {
          this.sendSocket(this.param);
        }
        this.isReconnect = false
        //存入连接成功的设备信息
        let currentItem = sessionStorage.getItem('curApiItem')
        localStorage.setItem('lastUrl', currentItem)
        //触发当前页面open回调，在页面上做一些页面自己的逻辑
        this.$refs.currentPage.onOpen()
      };

      //服务端消息推送
      this.socket.onmessage = (message) => {
        // 对数据进行解密
        let decryptData = JSON.parse(decryptPass(message.data));
        console.log(decryptData, "onmessage>>>>>onmessage home");
        // //接收到cmd为99的指令，说明后端开始发送心跳了
        if (decryptData.cmd == "99") {
          if (this.heartBeat) {
            clearTimeout(this.heartBeat)
          }
          //设置初始定时器，每隔10s接收心跳，超时进行重连操作
          this.heartBeat = setTimeout(() => {
            this.reconnect()
          }, 15000)
          //cmd为100则为心跳指令，说明当前设备连接正常，超过10s可以视为设备关机，会主动进行重连
        } else if (decryptData.cmd == "100") {
          if (this.heartBeat) {
            clearTimeout(this.heartBeat)
          }
          this.heartBeat = setTimeout(() => {
            this.reconnect()
          }, 15000)
          //cmd为101则是设备安装/卸载相关指令
        } else if (decryptData.cmd == "101") {
          let path = ['/apply', '/Detail', '/upload', '/upload', '/recommend']
          if (path.includes(this.$route.path)) {
            this.$refs.currentPage.onMessage(decryptData);
          }
          //cmd为104则是一些提示信息
        } else if (decryptData.cmd == "104") {
          console.log(decryptData.data, "decryptData.data home");
          // 不支持遥控的设备
          if (decryptData.data && decryptData.data.controlEnable === false) {
            Toast.fail("该设备暂不支持遥控器!");
          }
          //其他信息，是一些包含具体状态的信息
        } else {
          // let app = this.$store.state.appList
          // let movie = this.$store.state.movieList
          // let swipe = this.$store.state.swipeList
          // if (app?.length && movie?.length && swipe?.length) {
          //   this.$store.commit('changeListState', decryptData.data)
          // }
          // 将APP列表传回给对应页面
          console.log(decryptData.data, "decryptData.datas");
          this.$refs.currentPage.onMessage(decryptData);
        }
      };

      //连接关闭
      this.socket.onclose = (close) => {
        console.log(
          "连接关闭了,当前状态为",
          close.target.readyState,
          "，状态码",
          close.code,
          "，ws地址：",
          close.currentTarget.url
        );
        //移除相关本地存储以及清空定时器
        sessionStorage.removeItem('changed')
        sessionStorage.removeItem('apiUrls')
        this.$store.commit('removeList', '')
        Toast.clear()
        if (this.timeOut) {
          clearTimeout(this.timeOut);
        }

        // 如果不是正常关闭，则移除storage进行处理并提示，触发关闭事件给对应组件
        if (close.code != 3000) {
          this.socket = null;
          let path = ['/upload', '/upload', '/recommend']
          if (!path.includes(this.$route.path)) {
            Toast("设备连接已断开");
          }
          this.$refs.currentPage.close()
          if (!this.isReconnect) {
            this.reconnect()
          }
        }
      };

      //连接错误
      this.socket.onerror = (err) => {
        console.log("连接出现错误，" + err);
        Toast.clear()
        let path = ['/upload', '/upload', '/recommend']
        if (!path.includes(this.$route.path)) {
          // 触发错误事件到对应页面进行处理，并进行本地存储的移除
          Toast.fail('设备连接已断开')
        }
        this.$refs.currentPage.onError()
      };
    },
    // socket发送信息
    sendSocket(params) {
      this.socket && this.socket.readyState == 1 && this.socket?.send(cryptoPass(JSON.stringify(params)));
      this.checkConnect()
    },

    closeSocket() {
      this.socket && this.socket.close(3000, '客户端主动关闭')
      this.socket = null
    },
    //检查socket连接状态
    checkConnect() {
      console.log(this.socket, '当前socket状态')
    },
    //socket重连时触发
    reconnect() {
      //如果已重连过，且没有连通，则不再进行重连操作
      if (this.isReconnect) {
        this.removeStorage()
        // Toast.clear()
      } else {
        Toast.loading({
          message: "重连中...",
          forbidClick: true,
          duration: 500,
        });
        console.log('重连了')
        if (this.socket) {
          this.socket.close(3000, '客户端主动关闭')
          this.socket = null
          console.log('客户端已关闭，正在重新连接')
        }
        this.isReconnect = true
        this.connectSocket(0)
      }
    },

    removeStorage() {
      sessionStorage.removeItem("apiUrls");
      sessionStorage.removeItem("appList");
      sessionStorage.removeItem("curIndex");
      sessionStorage.removeItem("curApiItem");
    }
  },
  destroyed() {
    sessionStorage.removeItem("apiUrls");
    sessionStorage.removeItem("curIndex");
    sessionStorage.removeItem("curApiItem");
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.page-wrapper {
  width: 100%;
  height: calc(100% - 50px);
  overflow-x: hidden;
  overflow-y: auto;
}

.inline {
  display: inline-block;
  vertical-align: middle;
}

/*兼容ios旋转屏幕字体会变大的问题*/
@media screen and (max-device-width: 320px) {
  body {
    -webkit-text-size-adjust: none
  }
}

@media screen and (max-device-width: 480px) {
  body {
    -webkit-text-size-adjust: none
  }
}

@media screen and (max-device-width: 768px) {
  body {
    -webkit-text-size-adjust: none
  }
}

@media screen and (max-device-width: 1024px) {
  body {
    -webkit-text-size-adjust: none
  }
}

.van-toast__text {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -khtml-user-select: none !important;
  -ms-user-select: none !important;

}
</style>
