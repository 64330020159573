<template>
  <van-popup v-model="popupShow" round :closeable="closeable" position="bottom" :close-on-click-overlay="clickOverlay"
    safe-area-inset-bottom :style="{ height: `${height}` }" class="vanPopup" @closed="popupClose">
    <div class="title">{{ title }}</div>
    <div class="popup-content">
      <template v-if="apilist.length > 0">
        <div :class="{ itemCom: true, itemActive: index == curIndex }" v-for="(item, index) in apilist"
          :key="item.deviceId" @click="itemChange(item, index)">
          <img v-if="index == curIndex" class="inline icon" src="../assets/seleIconActive.png" />
          <img v-else class="inline icon" src="../assets/seleIcon.png" />
          <div class="text-com">
            <p class="name">
              {{ item.deviceName }}
            </p>
            <p class="ipcom">
              {{ item.hostIp }}
            </p>
          </div>
          <img v-if="index == curIndex" class="selectIcon" src="../assets/selected.png" />
        </div>
      </template>
      <div v-else class="nodata">没有可用设备</div>
    </div>
    <van-button color="linear-gradient(139deg, #00DAEE 0%, #00B6FF 100%)" block v-if="btnShow" style="margin: 15px 0"
      :disabled="!apilist.length > 0" @click="submitBtn" :loading="loading" loading-text="连接中...">确定</van-button>
  </van-popup>
</template>

<script>
export default {
  props: {
    submit: Function,
  },
  data() {
    return {
      closeable: true,
      clickOverlay: false,
      title: "选择设备",
      btnShow: true,
      height: "40%",
      popupShow: false,
      apilist: [],
      curIndex: null,
      loading: false,
    };
  },
  computed: {},
  methods: {
    show() {
      this.popupShow = true;
    },
    popupClose() {
      this.closeable = true;
      this.clickOverlay = false;
      this.title = "选择设备";
      this.btnShow = true;
      this.height = "40%";
      this.popupShow = false;
      this.curIndex = null;
      this.loading = false;
      setTimeout(() => {
        this.apilist = [];
      }, 500)
    },
    itemChange(item, i) {
      this.curIndex = i;
    },
    submitBtn() {
      let curApiItem = this.apilist[this.curIndex];
      if (curApiItem && curApiItem.extra) {
        let urls = curApiItem.extra.map((i) => {
          return i.link;
        });
        this.loading = true;
        this.submit &&
          this.submit({
            urls,
            curApiItem,
            curIndex: this.curIndex,
          }).then((res) => {
            console.log(res, "res");
          });
        let url = sessionStorage.getItem('apiUrls') || ''
        let json = JSON.stringify(urls)
        if (url != json) {
          sessionStorage.removeItem("appList");
        }
      } else {
        this.$toast("请选择一个设备");
      }
    },
  },
  mounted() { },
};
</script>
<style lang="less">
.vanPopup {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0px 15px;

  .title {
    padding: 15px 0;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #2a2a2a;
  }

  .popup-content {
    flex: 1;
    overflow-y: auto;

    .itemCom {
      background: #f6f8fa;
      border-radius: 8px;
      padding: 5px 30px 5px 15px;
      margin-top: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border: 1px solid #f6f8fa;
      position: relative;

      &:first-child {
        margin-top: 0px;
      }

      .icon {
        width: 19px;
      }

      .text-com {
        flex: 1;
        overflow: hidden;
        padding-left: 14px;
      }

      .name {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4d4f51;
      }

      .ipcom {
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4d4f51;
        margin-top: 2px;
      }

      .selectIcon {
        width: 30px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    .itemActive {
      background: rgba(0, 184, 254, 0.09);
      border-color: #00b9fe;

      .name,
      .ipcom {
        color: #009dff;
      }
    }

    .nodata {
      color: #999;
      text-align: center;
      margin-top: 30%;
    }
  }
}
</style>
