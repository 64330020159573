<template>
  <van-pull-refresh
    disabled
    v-model="loading"
    @refresh="onRefresh"
    class="page"
  >
    <slot></slot>
  </van-pull-refresh>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    onRefresh() {
      this.loading = true;
      this.$nextTick(() => {
        this.$emit("refresh", this.finished);
      });
    },
    finished() {
      this.loading = false;
    },
  },
};
</script>
<style lang="less" scoped>
.page {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  background: #fff;
}
</style>
