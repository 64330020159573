<template>
  <page @refresh="onRefresh">
    <div class="page-wrapper home-wrapper">
      <div class="notice-com" v-if="!apiUrls.length > 0" @click="equipmentClick">
        <van-notice-bar :scrollable="false" text="设备未连接，点击设备按钮进行连接" />
      </div>
      <van-row class="vanRow" type="flex" justify="space-between" align="center" gutter="20">
        <van-col span="8" class="vanCol" @click="btmClick(3)">
          <div class="oneIcon comIcon"></div>
          <div class="iconName">主页</div>
        </van-col>
        <van-col span="8" class="vanCol" @click="btmClick(26)">
          <div class="twoIcon comIcon"></div>
          <div class="iconName">电源</div>
        </van-col>
        <van-col span="8" class="vanCol" @click="equipmentClick">
          <div class="thrIcon comIcon"></div>
          <div class="iconName">设备</div>
        </van-col>
      </van-row>
      <van-row class="vanRow" type="flex" justify="space-between" align="center" gutter="20">
        <van-col span="8" class="vanCol" @click="btmClick(25)">
          <div class="fourIcon comIcon"></div>
          <div class="iconName">音量-</div>
        </van-col>
        <van-col span="8" class="vanCol" @click="btmClick(24)">
          <div class="fiveIcon comIcon"></div>
          <div class="iconName">音量+</div>
        </van-col>
      </van-row>
      <div class="ok-wrapper">
        <div class="ok-comtent">
          <div class="okCom">
            <img class="circle" src="../assets/circle.png" />
            <div class="cenArror" @click="btmClick(23)"></div>
            <div class="arrorRight" @click="btmClick(22)"></div>
            <div class="arrorLeft" @click="btmClick(21)"></div>
            <div class="arrorTop" @click="btmClick(19)"></div>
            <div class="arrorBottom" @click="btmClick(20)"></div>
          </div>
        </div>
      </div>
      <van-row class="vanRow" type="flex" justify="space-between" align="center" gutter="20">
        <van-col span="8" class="vanCol" @click="btmClick(82)">
          <div class="sixIcon comIcon"></div>
          <div class="iconName">菜单</div>
        </van-col>
        <van-col span="8" class="vanCol" @click="goDelivery()">
          <div class="eigIcon comIcon"></div>
          <div class="iconName">远程传送</div>
        </van-col>
        <van-col span="8" class="vanCol" @click="btmClick(4)">
          <div class="sevIcon comIcon"></div>
          <div class="iconName">返回</div>
        </van-col>
      </van-row>
    </div>
    <selectPopup :submit="selectSubmit" ref="selectPopupRef" />
  </page>
</template>

<script>
import selectPopup from "@/components/selectPopup.vue";
import { post } from "@/utils/request";
import { Toast } from "vant";

export default {
  components: { selectPopup },
  data() {
    return {
      apiUrls: [],
      curUrlIndex: 0,
      socket: null,
      upLoadUrl: null,
      timer: null,
      onOpenSet: null
    };
  },
  methods: {
    //连接前，查询设备列表
    equipmentClick() {
      let isConnect = this.socket ? '连接中...' : '扫描中...'
      Toast.loading({
        message: isConnect,
        forbidClick: true,
        duration: 0
      });
      post("/push/web/equipment/list", {}).then((res) => {
        if (res.code === 200) {
          console.log(res.data, "接口返回设备");
          if (res.data instanceof Array && res.data.length > 0) {
            let data = res.data;
            // let data = [{
            //   deviceId: '2f86bb2ff41cc0141701febc3a3d0eec',
            //   deviceName: '智能设备',
            //   extra: [{
            //     link: 'ws://192.168.3.238:10002'
            //   }],
            //   hostIp: '192.168.3.238',
            //   mac: '80:90:24:aa:cb:40'
            // }]
            //开始循环试连
            this.handleEquipWS(data, 0, 0);
          } else {
            //未查询到设备
            this.$toast("搜索设备失败，请检查手机与设备是否在同一局域网内");
          }
        }
      })
        .catch((err) => {
          console.log(err, "err");
          Toast.clear();
        });
    },
    //itemEquipmentIndex-设备index,   curWsIndex-设备地址index
    handleEquipWS(data, itemEquipmentIndex, curWsIndex) {
      // debugger
      console.log('开始轮询')
      console.log(`当前设备index为${itemEquipmentIndex}, 地址index为${curWsIndex}`);
      let itemEquipment = data[itemEquipmentIndex]; // 单个设备信息
      let wsList = itemEquipment.extra;
      let socket = new WebSocket(wsList[curWsIndex].link);
      console.log(socket.url, '发起新的socket请求')
      //模仿websocket超时处理
      this.timer = setTimeout(() => {
        // debugger
        // console.log(socket, socket.url, socket.readyState, "TTTTTTTTTTTTTT");
        if (socket && socket.readyState === 0) {
          //readyState 0 表示正在建立连接
          console.log('处理webSocket超时!');
          socket.close(3000, "处理webSocket超时!");
        }
      }, 5000);
      // console.log(socket.url, "socket.readyState", socket.readyState,);
      //socket连接通了则继续下一个
      socket.onopen = (e) => {
        // debugger
        console.log(e.currentTarget.url, "socket连接成功");
        // debugger
        clearTimeout(this.timer)
        if (e.currentTarget.url == socket.url) {
          data[itemEquipmentIndex].status = 0;
          this.$set(wsList[curWsIndex], 'enable', true)
          socket && socket.close(3000, "客户端主动关闭");
          this.onOpenSet = setTimeout(() => {
            // debugger
            // wsList[curWsIndex].enable = true
            this.handleCommModule(data, itemEquipmentIndex, curWsIndex);
          }, 1100)
        }
      };
      //socket连接失败，断开连接，将此WS地址状态设为 -1，代表不可用
      socket.onerror = (e) => {
        // debugger
        console.log(e, socket, "socket出错了");
        clearTimeout(this.timer)
        if (this.onOpenSet) {
          clearTimeout(this.onOpenSet)
        }
        if (e.currentTarget.url == socket.url) {
          curWsIndex++;
          setTimeout(() => {
            if (curWsIndex <= wsList.length - 1) {
              socket = null;
              this.handleEquipWS(data, itemEquipmentIndex, curWsIndex);
            } else {
              if (data[itemEquipmentIndex].status === 0) {
                this.handleCommModule(data, itemEquipmentIndex, curWsIndex);
              } else {
                data[itemEquipmentIndex].status = -1;
                this.handleCommModule(data, itemEquipmentIndex, curWsIndex);
              }
              // console.log(data, `轮询第${itemEquipmentIndex}次`)
            }
          }, 1000)
        }
      };

      socket.onclose = (e) => {
        // debugger
        console.log(e.target.readyState, '连接关闭了,状态码为', e.code, '地址：', e.currentTarget.url)
      }
    },
    //设备index + 1
    handleCommModule(data, itemEquipmentIndex, curWsIndex) {
      itemEquipmentIndex++;
      if (itemEquipmentIndex <= data.length - 1) {
        curWsIndex = 0;
        this.handleEquipWS(data, itemEquipmentIndex, curWsIndex);
      } else {
        //处理最终可用的设备列表 根据status == -1 为不可用，进行过滤
        console.log(data, "过滤前的设备");
        const promise = new Promise((resolve) => {
          // setTimeout(() => {
          let list = data.filter((item) => {
            return item.status === 0
          })
          console.log(list, "list home");
          resolve(list);
          // }, 5000);
        });
        promise.then((res) => {
          console.log(res, "过滤完可以连接的设备");
          if (res.length === 0) {
            this.$toast("搜索设备失败，请检查手机与设备是否在同一局域网内");
            //最后剩余可用连接只有1个时，直接连接
          } else if (res.length === 1) {
            this.handleHasOne(res);
            //最后剩余多个可用连接时，弹出弹框，让用户选择
          } else {
            // 查询有效设备中是否有上次连接过的设备
            if (localStorage.getItem('lastUrl')) {
              // 有则将此设备提至第一位
              let lastConnect = JSON.parse(localStorage.getItem('lastUrl')) || ''
              res.forEach((item, index) => {
                if (item.hostIp == lastConnect.hostIp) {
                  res.unshift(...res.splice(index, 1))
                  sessionStorage.setItem('curIndex', 0)
                }
              })
              this.onRefresh();
            } else {
              //没有则将上次连接设备信息进行删除
              localStorage.removeItem('lastUrl')
            }
            //调起弹窗
            const dialog = this.$refs.selectPopupRef;
            dialog.apilist = this.filterUrl(res) || [];
            console.log(dialog.apilist, "dialog.apilist home");
            dialog.curIndex = JSON.parse(sessionStorage.getItem("curIndex")) ?? null;
            this.$nextTick(() => {
              dialog.show();
              Toast.clear();
            });
          }
        });
      }
    },
    //最后过滤只剩一个设备时，调用此方法，直接连接
    handleHasOne(data) {
      //获取弹窗节点
      const dialog = this.$refs.selectPopupRef;
      //处理WS地址，返回一个只含有WS地址的数组
      let urls = data[0].extra.map((i) => {
        return i.link;
      });
      //存入信息
      sessionStorage.setItem("apiUrls", JSON.stringify(urls));
      sessionStorage.setItem("curIndex", JSON.stringify(0));
      sessionStorage.setItem("curApiItem", JSON.stringify(data[0]));
      this.onRefresh();
      //弹窗渲染 + 显示
      // dialog.btnShow = false;
      dialog.height = "30%";
      dialog.title = "";
      dialog.closeable = false;
      // dialog.btnShow = false
      // dialog.clickOverlay = true;
      dialog.apilist = this.filterUrl(data) || [];
      dialog.curIndex = JSON.parse(sessionStorage.getItem("curIndex")) ?? null;
      this.$nextTick(() => {
        dialog.show();
        Toast.clear();
      });
    },
    //弹窗前重新遍历一遍数组，根据之前遍历时存放的enable属性来判断此设备下多个link中的无效link，并将其过滤
    filterUrl(list) {
      console.log(list, 'listttttttttt')
      list.forEach((item, idx) => {
        item.extra = item.extra.filter((urls, index) => {
          return urls.enable == true
        })
        if (!item.extra.length) {
          list.splice(idx, 1)
        }
      })

      return list
    },

    onRefresh() {
      this.curUrlIndex = 0;
      this.apiUrls = JSON.parse(sessionStorage.getItem("apiUrls")) || [];
    },

    selectSubmit(data) {
      return new Promise((resolve) => {
        setTimeout(() => {
          console.log(data, 'dataaaaaaaaaaaaaaaa')
          sessionStorage.setItem("apiUrls", JSON.stringify(data.urls));
          sessionStorage.setItem("curIndex", JSON.stringify(data.curIndex));
          sessionStorage.setItem("curApiItem", JSON.stringify(data.curApiItem));
          this.onRefresh();
          let lastConnect = JSON.parse(localStorage.getItem('lastUrl')) || ''
          if (lastConnect && lastConnect.hostIp != data.curApiItem.hostIp) {
            this.$emit('closeSocket')
          }
          this.handleWS();
          resolve(true);
        }, 1000);
      });
    },
    //建立webSocket连接
    handleWS() {
      this.$emit('connectSocket', this.curUrlIndex)
    },
    //连接成功后，操作遥控器按钮
    btmClick(num) {
      if (!this.apiUrls.length > 0) {
        this.$toast("设备未连接，点击设备按钮进行连接");
        return;
      }
      let param = {
        cmd: 1,
        data: {
          keyCode: num,
        }
      };
      this.$emit('sendSocket', param)
      // this.socket && this.socket.send(cryptoPass(JSON.stringify(param)));
    },

    goDelivery() {
      if (!this.apiUrls.length > 0) {
        this.$toast("设备未连接，点击设备按钮进行连接");
        return;
      }
      let param = {
        cmd: 5,
        data: {},
      };
      this.$emit('sendSocket', param)
      // this.socket && this.socket.send(cryptoPass(JSON.stringify(param)));
    },

    onOpen() {
      this.$refs.selectPopupRef.loading = false
      this.$refs.selectPopupRef.popupClose()
    },
    //cmd为105则是返回远程推送上传的地址，将此参数带给上传页面
    onMessage(decryptData) {
      if (decryptData.cmd == "105") {
        this.$router.push({ path: '/upload', query: { url: decryptData.data.serverUrl, flag: true } })
      }
    },

    close() {
      this.apiUrls = []
      if (this.$refs.selectPopupRef.popupShow == true) {
        this.$refs.selectPopupRef.popupClose()
      }
    },

    onError() { },
    //触发此方法关闭设备列表弹窗
    connected() {
      this.$refs.selectPopupRef.popupClose()
    },
  },

  created() {
    this.onRefresh();
    this.socket = null;
  },

  beforeDestroy() {
    clearTimeout();
    // sessionStorage.setItem('websocket', this.socket)
    // this.socket && this.socket.close(3000, "客户端主动关闭");
    this.socket = null;
    this.curUrlIndex = 0;
  },
};
</script>
<style lang="less">
.home-wrapper {
  .notice-com {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
  }

  .vanRow {
    padding: 4.2vw 0;

    .vanCol {
      text-align: center;

      .comIcon {
        width: 50%;
        padding-bottom: 50%;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
      }

      .oneIcon {
        background-image: url(../assets/one.png);
      }

      .oneIcon:active {
        background-image: url(../assets/oneActive.png);
      }

      .twoIcon {
        width: 60%;
        padding-bottom: 60%;
        background-image: url(../assets/two.png);
      }

      .twoIcon:active {
        background-image: url(../assets/twoActive.png);
      }

      .thrIcon {
        background-image: url(../assets/thr.png);
      }

      .thrIcon:active {
        background-image: url(../assets/thrActive.png);
      }

      .fourIcon {
        background-image: url(../assets/four.png);
      }

      .fourIcon:active {
        background-image: url(../assets/fourActive.png);
      }

      .fiveIcon {
        background-image: url(../assets/five.png);
      }

      .fiveIcon:active {
        background-image: url(../assets/fiveActive.png);
      }

      .sixIcon {
        background-image: url(../assets/six.png);
      }

      .sixIcon:active {
        background-image: url(../assets/sixActive.png);
      }

      .sevIcon {
        background-image: url(../assets/sev.png);
      }

      .eigIcon {
        background-image: url(../assets/delivery.png);
      }

      .eigIcon:active {
        background-image: url(../assets/deliverys.png);
      }

      .sevIcon:active {
        background-image: url(../assets/sevActive.png);
      }

      .iconName {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4d4f51;
      }
    }
  }

  .ok-wrapper {
    position: relative;
    font-size: 0;

    .ok-comtent {
      position: relative;
      display: inline-block;
      width: 274px;
      left: 50%;
      margin-left: -137px;

      .cenArror {
        width: 44%;
        padding-bottom: 44%;
        display: inline-block;
        position: absolute;
        left: 50%;
        top: calc(50% - 3px);
        transform: translate(-50%, -50%);
        z-index: 4;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../assets/ok.png);
      }

      .cenArror:active {
        background-image: url(../assets/okActive.png);
      }

      .arrorTop {
        width: 88%;
        padding-bottom: 32.6%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 3;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../assets/top.png);
      }

      .arrorTop:active {
        background-image: url(../assets/topActive.png);
      }

      .arrorBottom {
        width: 86%;
        padding-bottom: 33%;
        position: absolute;
        bottom: 7px;
        left: 50%;
        z-index: 3;
        background-size: contain;
        background-repeat: no-repeat;
        transform: translate(-50%, 0) rotate(180deg);
        background-image: url(../assets/top.png);
      }

      .arrorBottom:active {
        background-image: url(../assets/topActive.png);
      }

      .arrorRight {
        height: 89%;
        padding-left: 32%;
        position: absolute;
        right: 4px;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 3;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../assets/right.png);
      }

      .arrorRight:active {
        background-image: url(../assets/rightActive.png);
      }

      .arrorLeft {
        height: 89%;
        padding-left: 32%;
        position: absolute;
        left: 4px;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 3;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(../assets/left.png);
      }

      .arrorLeft:active {
        background-image: url(../assets/leftActive.png);
      }
    }

    img.circle {
      width: 100%;
      position: relative;
      z-index: 1;
    }
  }
}
</style>
