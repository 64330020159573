import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appList: [],
    movieList: [],
    swipeList: [],
  },

  getters: {
    getList(state) {
      return state;
    },
  },

  mutations: {
    setAppList: (state, payload) => {
      state.appList = payload;
    },

    setMovieList: (state, payload) => {
      state.movieList = payload;
    },
    setSwipeList: (state, payload) => {
      state.swipeList = payload;
    },

    changeListState: (state, payload) => {
      for (let i = 0; i < 3; i++) {
        let name = i == 0 ? "appList" : i == 1 ? "movieList" : "swipeList";
        state[name].forEach((item) => {
          if (item.pkg == payload?.pkg) {
            if (payload.action == 1) {
              if (Number(item.verCode) - Number(payload.verCode) > 0) {
                item.state = 3; //更新
              } else {
                item.state = 2; //打开
              }
            } else {
              if (payload.state) {
                item.state = payload.state;
              } else {
                item.state = 0; //下载
              }
            }
          }
        });
      }
      // state.appList.forEach(item => {
      //   if(item.pkg == payload?.pkg) {
      //     if(payload.action == 1) {
      //       if (Number(item.verCode) - Number(payload.verCode) > 0) {
      //         item.state = 3; //更新
      //       } else {
      //         item.state = 2; //打开
      //       }
      //     }else {
      //       if(payload.state) {
      //         item.state = payload.state
      //       } else {
      //         item.state = 0 //下载
      //       }
      //     }
      //   }
      // })
      // state.movieList.forEach(items => {
      //   if(items.pkg == payload?.pkg) {
      //     if(payload.action == 1) {
      //       if (Number(items.verCode) - Number(payload.verCode) > 0) {
      //         items.state = 3; //更新
      //       } else {
      //         items.state = 2; //打开
      //       }
      //     }else {
      //       if(payload.state) {
      //         items.state = payload.state
      //       } else {
      //         items.state = 0 //下载
      //       }
      //     }
      //   }
      // })
    },

    removeList: (state, payload) => {
      state.appList = [];
      state.movieList = [];
      state.swipeList = [];
    },
  },

  actions: {},
});
