import axios from "axios";
import { Toast } from "vant";
import md5 from "js-md5";
import { setToken, setTimeStamp } from "@/utils/cookie";

// const baseUrl = process.env.VUE_APP_BASE_URL || "http://192.168.0.11:8082/"; //本地环境
// const baseUrl =
//   process.env.VUE_APP_BASE_URL || "http://test.remote.misetv.com/"; //测试环境
// const baseUrl = "http://test.remote.misetv.com/"; // 测试环境
// const baseUrl = "http://dev.remote.misetv.com"; // 开发环境
const baseUrl = "http://tvpush.ikukan.cn/"; //  正式环境
var instance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
  },
});

export function get(url, params) {
  return instance.get(url, { params });
}

export function post(url, data) {
  return instance.post(url, data);
}

//全局请求拦截和响应拦截
instance.interceptors.request.use(
  function (config) {
    let a = (Math.floor(Math.random() * (9999 - 1000)) + 1000).toString();
    let b = (Math.floor(Math.random() * (9999 - 1000)) + 1000).toString();
    let time = Date.now().toString();
    let e = a + b + time + "kk123456";
    let token = a + md5(e) + b;
    setToken(token);
    setTimeStamp(time);
    config.headers.token = token;
    config.headers.timeStamp = time;
    if (config.method == "get") {
      if (config.url.indexOf("?") > -1) {
        config.url = config.url + "&randomTime=" + new Date().getTime();
      } else {
        config.url = config.url + "?randomTime=" + new Date().getTime();
      }
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    const { code, msg } = response.data;
    if (code !== 200) {
      onError(msg);
      return false;
    }
    return response.data || {};
  },
  function (error) {
    const message =
      (error.response && error.response.data && error.response.data.msg) ||
      error.message;
    onError(message);
    return Promise.reject(error);
  }
);

function onError(message) {
  Toast.fail(message);
}
