import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "酷看遥控",
    },
  },
  {
    path: "/apply",
    name: "Apply",
    component: () =>
      import(/* webpackChunkName: "apply" */ "@/views/Apply.vue"),
    meta: {
      // keepAlive: true,
      title: "应用推送",
    },
  },
  {
    path: "/detail",
    name: "Detail",
    component: () =>
      import(/* webpackChunkName: "detail" */ "@/views/Detail.vue"),
    meta: {
      title: "应用详情",
    },
  },
  {
    path: "/upload",
    name: "upload",
    component: () =>
      import(/* webpackChunkName: "detail" */ "@/views/push/index.vue"),
    meta: {
      title: "远程推送",
    },
  },
  {
    path: "/recommend",
    name: "recommend",
    component: () =>
      import(/* webpackChunkName: "detail" */ "@/views/recommend/index.vue"),
    meta: {
      title: "推荐应用",
    },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
