import * as CryptoJS from "crypto-js";
export const cryptoPass = (word) => {
  // 加密密码
  const key = CryptoJS.enc.Utf8.parse("1217FD84CF07FAFD29ABBF930E70D8EC");
  let srcs = CryptoJS.enc.Utf8.parse(word);
  // AES中并没有64位的块,如果采用PKCS5, 那么实质上就是采用PKCS7
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

export const decryptPass = (word) => {
  // 解密
  const key = CryptoJS.enc.Utf8.parse("1217FD84CF07FAFD29ABBF930E70D8EC");
  // AES中并没有64位的块,如果采用PKCS5, 那么实质上就是采用PKCS7
  let decrypt = CryptoJS.AES.decrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  });
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
};

export default CryptoJS;
