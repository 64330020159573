import * as Cookies from "js-cookie";
const TokenKey = "KK-WX-TOKEN";

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function getToken() {
  return Cookies.get(TokenKey);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

const TimeStampKey = "KK-WX-TIMESTAMP";
export function setTimeStamp(time) {
  return Cookies.set(TimeStampKey, time);
}

export function getTimeStamp() {
  return Cookies.get(TimeStampKey);
}

export function removeTimeStamp() {
  return Cookies.remove(TimeStampKey);
}
